import { render, staticRenderFns } from "./ModalSatuSehatReupload.vue?vue&type=template&id=82c1621a"
import script from "./ModalSatuSehatReupload.vue?vue&type=script&lang=js"
export * from "./ModalSatuSehatReupload.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports